<template>
    <div id="discount" class="q-pa-md q-gutter-sm">
        <!-- 面包屑 -->
        <q-breadcrumbs>
            <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
            <q-breadcrumbs-el label="基础数据管理" icon="iconfont icon-ziyuan143" />
            <q-breadcrumbs-el label="优惠码管理" icon="iconfont icon-youhui" to="/Discount" />
        </q-breadcrumbs>
        <!-- 内容区 -->
        <div class='discountbox' id='discountbox'>
            <!-- 搜索区 -->
            <q-card class="my-card" style="margin-bottom: 10px;">
                <q-card-section>
                    <div class="searchbox" id="searchbox">
                        <div class="search">
                            <div class='leftbox'>
                                <el-select v-model="queryData.status" clearable  placeholder="使用状态" @change='change' @clear='change'
                                    :disabled='Permissionslist.filter(item=>item==="discount.list").length>0?false:true'
                                >
                                    <el-option
                                    v-for="item in discountStatus"
                                    :key="item.key"
                                    :label="item.value"
                                    :value="item.key">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class='leftbox'>
                                <el-input v-model="queryData.discountCode" placeholder="优惠码" clearable 
                                    @clear='change' @blur='change'
                                    :disabled='Permissionslist.filter(item=>item==="discount.list").length>0?false:true'
                                >
                                </el-input>
                            </div>
                            <!-- <el-button icon="el-icon-refresh" size="mini" circle @click='refresh'></el-button> -->
                        </div>

                        <div class="addbtn">
                            <el-button
                                icon="el-icon-refresh"
                                type="primary"
                                size='medium'
                                @click='getDiscountList'
                            >
                                刷新
                            </el-button>
                            <el-button
                                type="primary"
                                size='medium'
                                @click='add'
                                :disabled='Permissionslist.filter(item=>item==="discount.add").length>0?false:true'
                            >
                                添加优惠码
                            </el-button>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
            <!-- 列表区域 -->
            <!-- 
                v-loading="ordervisible"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(55,55,55,0.2)"
                :max-height="tableHeight"
                @sort-change="sortthiscolumn"
             -->
            <q-card class="my-card">
                <q-card-section>
                    <el-table
                        ref="multipleTable"
                        :data="discountList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="ordervisible"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(55,55,55,0.2)"
                        :max-height="tableHeight"
                    >
                        <template slot="empty">
                            {{nodatetype}}
                        </template>
                        <!-- 优惠码 -->
                        <el-table-column show-overflow-tooltip align='center' prop="discountCode" label="优惠码" min-width="110"></el-table-column>
                        <!-- 折扣 -->
                        <el-table-column show-overflow-tooltip align='center' prop="discountPercent" label="折扣" min-width="120"></el-table-column>
                        <!-- 当前状态 -->
                        <el-table-column class="diskContent" show-overflow-tooltip align='center' prop="status" label="当前状态" min-width="145">
                            <template slot-scope="{row}">
                                <el-tag :type="discountStatuscolor[row.status]">
                                    {{ (discountStatus.filter(item=>item.key == row.status).pop() || {} ).value}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <!-- 创建日期 -->
                        <el-table-column show-overflow-tooltip align='center' prop="createTime" label="创建日期" min-width="151"></el-table-column>
                        <!-- 操作 -->
                        <el-table-column align='center' min-width="230" width="250" fixed="right" label="操作">
                            <template slot-scope="scope">
                                <!-- 领取任务  :disable='scope.row.checkStatus==0? false : true'-->
                                <q-btn
                                    class="czbtn" 
                                    size="sm" 
                                    color="blue" 
                                    icon="iconfont icon-Edit"
                                    @click="edit(scope.row)"
                                    :disabled='Permissionslist.filter(item=>item==="discount.edit").length>0?false:true'
                                    >
                                    <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                                        编辑优惠码
                                    </q-tooltip>  
                                </q-btn> 
                                <!-- 提交审核 :disable='scope.row.checkStatus==0? true : false'-->
                                <q-btn 
                                    class="czbtn"  
                                    size="sm" 
                                    color="red-5" 
                                    icon="iconfont icon-ashbin"
                                    @click="del(scope.row)"
                                    :disabled='(Permissionslist.filter(item=>item==="discount.delete").length>0 && scope.row.status == 0) ?false:true'
                                    >
                                    <q-tooltip content-class="bg-red-5" :offset="[10, 10]">
                                        删除
                                    </q-tooltip>  
                                </q-btn>      
                            </template>
                        </el-table-column>
                    
                    </el-table>
                    <!-- 分页组件 -->
                    <elementPagination class="Paginationstyle" :total='queryData.total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
                </q-card-section>
            </q-card>
        </div>

        <!-- 新增节点 -->
        <addDiscount
            ref='addDiscount'
            :addConfig.sync='addConfig'
            :addData.sync='addData'
            @addClose='addClose'
            @updateList='getDiscountList'
        />
        
        <!-- 编辑节点 -->
        <editDiscount
            :editConfig.sync='editConfig'
            :editData.sync='editData'
            @editClose='editClose'
            @updateList='getDiscountList'
        />
    </div>
</template>
<script>
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度

import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import addDiscount from '@/components/Discount/addDiscount.vue'; // 新增节点
import editDiscount from '@/components/Discount/editDiscount.vue'; // 新增节点

import { discountLists,discountDelete,discountData,discountSave } from '@/api/Discount.js'
import { makeDebounce } from "@/utils/debounce.js";  
import { mapState } from 'vuex'
import { notifys } from '@/api/components/Notify.js'

export default {
    data() {
        return {
            nodatetype:'',
            ordervisible: false,//控制列表加载画面
            tableHeight:500, //列表最大高度
            // 分页设置
            queryData:{ // element分页信息
                page: 1,
                pageSize: 10,
                total:0,
                status:'',//优惠码状态
                discountCode:'', //优惠码
            },
            discountList:[], //所有节点

            value:'',
            
            discountStatuscolor:{
                0:'success',
                1:'danger',
                2:'warning',
            },

            //新增数据
            addConfig: false, // 控制新增
            addData:{
                num:'', //折扣数量
                discountPercent:'', //折扣力度
            },

            //编辑数据
            editConfig: false, // 控制编辑
            editData:{
                discountCode:'', //折扣数量
                discountPercent:'', //折扣力度
                id:'',
            },
        }
    },
    created(){
        this.getDiscountList()
    },
    mounted() {
        
    },
    computed:{
        ...mapState([
            'discountStatus', //优惠码使用状态
            'orderUrgent',//加急状态
            'Permissionslist', // 权限词典
        ])
    },
    components:{
        elementPagination,
        addDiscount,
        editDiscount,
    },
    methods:{
        refresh:makeDebounce(function(evt){
            let target = evt.target;
            if(target.nodeName == "SPAN"){
                target = evt.target.parentNode;
            }
            target.blur()
            this.getMonitorList()
        },300),

        //获取优惠码列表
        getDiscountList(){
            this.nodatetype=''
            this.ordervisible = true
            setTimeout(async() =>{
                const { data:res } = await discountLists({
                    ...this.queryData,
                })
                console.log('优惠码列表',res);
                if(res.code===20000){
                    this.discountList = res.data.discounts
                    this.queryData.total=res.data.totals*1

        
                    let height = document.getElementById('searchbox').offsetHeight + 80
                    this.tableHeight = getHeight('discountbox',height)

                    this.ordervisible = false
                    this.nodatetype='暂无数据'
                }else{
                    this.ordervisible = false
                    this.nodatetype='数据获取失败'
                }
                
            },1000)
            // let height = document.getElementById('searchbox').offsetHeight + 20
            // this.tableHeight = getHeight('systemMonitoringbox',height)
        },
        change(){
            this.queryData.page = 1
            this.getDiscountList()
        },
        //排序
        // sortthiscolumn(column){
        //     console.log(column);
        //     if(column.order=='ascending'){
        //         //升序
        //         this.queryData.sortType = 1
        //         this.queryData.orderField = column.prop
        //         this.getDiscountList()
        //     }else if(column.order=='descending'){
        //         //降序
        //         this.queryData.sortType = 0
        //         this.queryData.orderField = column.prop
        //         this.getDiscountList()
        //     }else{
        //         //默认按照时间排序
        //         this.queryData.sortType = 0
        //         this.queryData.orderField = ''
        //         this.getDiscountList()
        //     }
        // },
        // 搜索框改变时
        optionChange(v){
            console.log(v);
            this.queryData.status = v
            this.getDiscountList()
        },
        // 搜索框清空时
        optionClear(v){
            console.log(v);
            this.queryData.status = ''
            this.getDiscountList()
        },
        
        //新增优惠码
        add(){
            this.addConfig = true
        },
        // 新增关闭时
        addClose(){
            this.addData.num=''
            this.addData.discountPercent=''
            this.addConfig = false
        },
        // 编辑节点
        async edit(row){
            console.log(row);
            const { data: res } = await discountData({
                id: row.id,
            })
            if(res.code===20000){
                console.log(res);
                this.editData = JSON.parse(JSON.stringify(res.data.discount)) 
                this.editConfig = true
            }
            // this.editData.id = row.id
        },
        //编辑关闭时
        editClose(){
            this.editConfig = false
        },
        // 删除节点
        del(row){
            this.$q.dialog({
                title: '删除',
                message: `你是否要删除此优惠码?`,
                ok: { label: '确定',color: 'red'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data: res } = await discountDelete({
                        id: row.id,
                    })
                    if(res.code===20000){
                        this.discountList.forEach((item, index) => {
                            // 如果当前id是要删除的id
                            if (item.id === row.id) {
                                // 判断规则:（当前页码-1）*页容量+要被删除项的索引+1===总条数 && 判断被删项索引是否为0 && 当前不是第一页
                                if (
                                    (this.queryData.page - 1) * this.queryData.pageSize + index + 1 ===
                                        this.queryData.total &&
                                    index === 0 &&
                                    this.queryData.page > 1
                                ) {
                                    // 如果是就将页码回到上一页
                                    this.queryData.page--;
                                }
                            }
                        });
                        this.getDiscountList() //刷新列表
                        notifys('positive',res.message)
                    }
                }catch (e){
                    notifys('negative',res.message)
                }
            })
        },
        
        //出生日期转换
        expireDateZH(expireDate){
            if(expireDate){
                let arr = expireDate.split('');
                arr.splice(4,0,'-');
                arr.splice(7,0,'-');
                var Date = arr.join('')
                return Date
            }else{
                return ''
            }
        },
        // 分页发送变化函数
        changepageSize(val){
            this.queryData.pageSize = val
            this.getDiscountList()
        },
        changePage(val){
            this.queryData.page = val
            this.getDiscountList()
        },
    },
}
</script>
<style lang="less" scoped>
#discount{
  height: 100%;
}
.discountbox{
  height: 90%;
}
.searchbox{
    display: flex;
    justify-content: space-between;
}
.search{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // align-items: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .operation{
        // margin: .625rem .625rem 0 .625rem;
        margin: 0 .625rem 0 .625rem;
        width: 12.5rem;
    }
    .leftbox{
        margin: 0 5px;
    }
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: center;
//   align-items: flex-end;
  flex-wrap: wrap;
    .el-button--primary {
        color: #FFF;
        background-color: #409EFF  ;
        border-color: #409EFF  ;
    }
}
.czbtn{
  margin-right: .75rem;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}

//popover 中字体颜色
.popoverColor{
    color: white;
    font-weight: bold;
}
//运行状态样式
.q-badge{
    padding: 10px;
}
//分页器样式
.Paginationstyle{
  margin-top: .9375rem;
}
</style>
