<template>
    <div>
        <q-dialog v-model="addConfigs" persistent  @hide="addClose">
            
            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">新增优惠券</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-form ref="myForm" greedy>
                        <!-- 生成数量 -->
                        <q-input class="add" mask="###" :dense='true' outlined  v-model="addDatas.num" label="生成数量*" :rules='[required.discountNum,required.discountNumLength]' />
                        <!-- 折扣力度 -->
                        <q-input class="add"
                            :dense='true'
                            :mask="addDatas.discountPercent >= 1 ? '###':'#.##'"
                            outlined
                            v-model="addDatas.discountPercent"
                            label="折扣力度*"
                            :rules='[required.discountPercent,required.discountPercentLength]' 
                        >
                                <template v-slot:append>
                                    <span style="font-size: 12px;">{{(addDatas.discountPercent*10 == 0 ? '' : addDatas.discountPercent*10)}}折</span>
                                </template>
                        </q-input>
                    </q-form>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="取消" @click="addClose" />
                    <q-btn flat label="添加" @click="addOk" />
                </q-card-actions>
            </q-card>
            
        </q-dialog>
    </div>
</template>
<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { discountGenerator } from '@/api/Discount.js'
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        addConfig:{
            type: Boolean,
            required: true,
        },
        addData:{
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            required,
            
        }
    },
    created(){
        
    },
    computed:{
        addConfigs:{
            get(){
                return this.addConfig
            },
            set(v){
                this.$emit('update:addConfig',v)
            }
        },
        addDatas:{
            get(){
                return this.addData
            },
            set(v){
                this.$emit('update:addData',v)
            }
        }
    },
    methods:{
        addOk(){
            // console.log(this.addDatas.num);
            // console.log(this.addDatas.discountPercent);
            this.$refs.myForm.validate().then( async outcome =>{
                if (outcome){
                    const { data: res } = await discountGenerator({
                        ...this.addDatas,
                    })
                    if(res.code===20000){
                        this.$emit('addClose')   // 关闭新增窗口
                        this.$emit('updateList') // 刷新列表
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                }
            })
        },
        addClose(){
            // this.$refs.myForm.resetValidation()
            this.$emit('addClose')
        }
    },
}
</script>
<style lang="less" scoped>

</style>
