<template>
    <div>
        <q-dialog v-model="editConfigs" persistent>
            
            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">编辑优惠券</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-form ref="myForm" greedy>
                        <!-- 生成数量 -->
                        <q-input
                            class="add"
                            disable
                            :dense='true'
                            outlined
                            v-model="editDatas.discountCode"
                            label="优惠码*"
                            :rules='[required.kong]' 
                        />
                        <!-- 折扣力度 -->
                        <q-input
                            class="add"
                            :dense='true'
                            :mask="editDatas.discountPercent >= 1 ? '###':'#.##'"
                            outlined
                            v-model="editDatas.discountPercent"
                            label="折扣力度*"
                            :rules='[required.discountPercent,required.discountPercentLength]' 
                        >
                            <template v-slot:append>
                                <span style="font-size: 12px;">{{(editDatas.discountPercent*10 == 0 ? '' : editDatas.discountPercent*10)}}折</span>
                            </template>
                        </q-input>
                    </q-form>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="取消" @click="editClose" />
                    <q-btn flat label="保存" @click="editOk" />
                </q-card-actions>
            </q-card>
            
        </q-dialog>
    </div>
</template>
<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { discountSave } from '@/api/Discount.js'
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        editConfig:{
            type: Boolean,
            required: true,
        },
        editData:{
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            required,
        }
    },
    created(){
    },
    computed:{
        editConfigs:{
            get(){
                return this.editConfig
            },
            set(v){
                this.$emit('update:editConfig',v)
            }
        },
        editDatas:{
            get(){
                return this.editData
            },
            set(v){
                this.$emit('update:editData',v)
            }
        }
    },
    methods:{
        editClose(){
            this.$emit('editClose')
        },
        editOk(){
            this.$refs.myForm.validate().then( async outcome =>{
                if (outcome){
                    const { data: res } = await discountSave({
                        id:this.editDatas.id,
                        discountPercent:this.editDatas.discountPercent,
                    })
                    if(res.code===20000){
                        this.$emit('editClose')   // 关闭新增窗口
                        this.$emit('updateList') // 刷新列表
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>

</style>
